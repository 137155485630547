import { toast } from 'react-hot-toast'

const initialState = {
  processCreationStatus: 1, // 1: Not started, 2: In progress, 3: Finished
  processIdCreated: ''
}

const BiometriaProcessReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_PROCESS_ERROR': {
      toast.error(action.error)
      return {
        ...state,
        processCreationStatus: 1
      }
    }
    case 'SET_CREATING_PROCESS': {
      return {
        ...state,
        processCreationStatus: 2
      }
    }
    case 'CREATE_PROCESS_SUCESS': {
      toast.success('Análise iniciada com sucesso!')
      return {
        ...state,
        processCreationStatus: 3,
        processIdCreated: action.response.check_id
      }
    }
    case 'RESET_PROCESS': {
      return {
        ...state,
        processCreationStatus: 1,
        processIdCreated: ''
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default BiometriaProcessReducer
