import { toast } from 'react-hot-toast'

const initialState = {
  smsProcessCreationStatus: 1 // 1: Not started, 2: In progress, 3: Finished
}

const SMSProcessReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_SMS_PROCESS_ERROR': {
      toast.error(action.error)
      return {
        ...state,
        smsProcessCreationStatus: 1
      }
    }

    case 'SET_CREATING_SMS_PROCESS': {
      return {
        ...state,
        smsProcessCreationStatus: 2
      }
    }

    case 'CREATE_SMS_PROCESS_SUCESS': {
      toast.success('Mensagem enviada com sucesso!')
      return {
        ...state,
        smsProcessCreationStatus: 3
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default SMSProcessReducer
