import { combineReducers } from 'redux'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import RelatorioReducer from './reducers/RelatorioReducer'
import HistoricoReducer from './reducers/HistoricoReducer'
import historicoGeralReducer from './reducers/HistoricoGeralReducer'
import CrudLocReducer from './reducers/CrudLocReducer'
import CrudUserReducer from './reducers/CrudUserReducer'
import SMSProcessReducer from './reducers/SMSProcessReducer'
import BiometriaProcessReducer from './reducers/BiometriaProcessReducer'
import ProfileReducer from './reducers/ProfileReducer'
import RelatorioAdminReducer from './reducers/RelatorioAdminReducer'
import BillingReducer from './reducers/BillingReducer'
import WebSocketReducer from './reducers/WebSocketReducer'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  rentals: CrudLocReducer,
  users: CrudUserReducer,
  relatorio: RelatorioReducer,
  relatorioAdmin: RelatorioAdminReducer,
  historico: HistoricoReducer,
  historicogeral: historicoGeralReducer,
  smsprocess: SMSProcessReducer,
  biometriaprocess: BiometriaProcessReducer, 
  profile: ProfileReducer,
  billing: BillingReducer, 
  process: WebSocketReducer
  
})

export default rootReducer
